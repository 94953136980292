<template>
    <div>
        <app-page-header></app-page-header>
        <app-loader></app-loader>

        <v-container grid-list-xl>
            <v-form id="form_parking_application" ref="form" v-model="valid" lazy-validation>
                <v-layout row wrap align-center justify-center fill-height>
                    <v-flex xs12 sm12 md10 lg10 xl8>
                        <v-card id="form_card">
                        <v-img
                        :src="imgParking"
                        height="500px"
                        ></v-img>
                            <v-card-title>
                                <span style="margin-right:5px;" class="subtitle-1 application--card--title text-center">
                                <v-icon left>mdi-file-document-edit-outline</v-icon>
                                Podatki o</span><v-chip label :color="application_media_type" dark >vlogi</v-chip>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <!-- backend validation messages -->
                                <form-validation-messages :messages="apiValidationMessages">
                                </form-validation-messages>

                                <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>

                                <info-user-type-domacin ></info-user-type-domacin>
                                <info-user-type-gost ></info-user-type-gost>

                                <div style="min-height:10px;"></div>
                                <v-radio-group v-model="row" row>
                                    <v-radio label="Dodaj novega uporabnika" value="radio-new-user"></v-radio>
                                    <v-radio label="Izberi obstoječega uporabnika" value="radio-existing-user"></v-radio>
                                </v-radio-group>

                                <div v-show="!newUser" style="min-height:10px;"></div>
                                <p v-if="!newUser" class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon> Iz podnjega seznama izberite uporabnika dovolilnice</p>
                                <!-- data table z imetniki-->
                                <div v-show="!newUser" style="margin-top:10px; margin-bottom:10px;">
                                    <select-permit-holder-data-table
                                    :settings="dataTableSettings"
                                    :queryParameters="queryParameters"
                                    ></select-permit-holder-data-table>   
                                </div>

                                <br v-if="!newUser">

                                <!-- imetnik -->
                                <v-text-field
                                    v-model="name"
                                    label="Ime in priimek"
                                    required
                                    :rules="name_rules"
                                    :readonly="!newUser"
                                    :disabled="!newUser"
                                ></v-text-field>

                                <!-- ulica -->
                                <v-text-field
                                    v-model="address"
                                    label="Naslov (prebivališče)"
                                    required
                                    :rules="address_rules"
                                    :readonly="!newUser"
                                    :disabled="!newUser"
                                ></v-text-field>

                                <!-- kraj -->
                                <v-text-field
                                    v-model="town"
                                    label="Kraj"
                                    required
                                    :rules="town_rules"
                                    :readonly="!newUser"
                                    :disabled="!newUser"
                                ></v-text-field>

                                <!-- poštna številka -->
                                <v-text-field
                                    v-model="postal_code"
                                    label="Poštna številka"
                                    required
                                    :rules="postal_code_rules"
                                    type="number"
                                    :readonly="!newUser"
                                    :disabled="!newUser"
                                ></v-text-field>

                                <!-- izbira prebivališča -->
                                <v-select
                                :items="residenceTypes"
                                label="Tip prebivališča"
                                item-text="name"
                                item-value="id"
                                v-model="residence_type"
                                :rules="residence_type_rules"
                                :readonly="!newUser && (existing_permit_user !== null && existing_permit_user.residence_type !== null)"
                                :disabled="!newUser && (existing_permit_user !== null && existing_permit_user.residence_type !== null)"
                                ></v-select>

                                <!-- telefonska številka
                                <v-text-field
                                    v-model="phone_number"
                                    label="Telefonska številka"
                                    required
                                    :rules="telephone_number_rules"
                                    type="number"
                                    :readonly="!newUser"
                                    :disabled="!newUser"
                                ></v-text-field>-->

                                <!-- telefonska številka -->
                                <v-text-field
                                    v-model="phone_number"
                                    label="Telefonska številka"
                                    type="number"
                                    :readonly="!newUser && (existing_permit_user != null && existing_permit_user.phone_number != null && existing_permit_user.phone_number != '')"
                                    :disabled="!newUser && (existing_permit_user != null && existing_permit_user.phone_number != null && existing_permit_user.phone_number != '')"
                                ></v-text-field>


                                <!-- emšo 
                                <v-text-field
                                    v-model="emso"
                                    label="EMŠO"
                                    required
                                    :rules="emso_rules"
                                    type="number"
                                    :disabled="!userTypeSelected"
                                ></v-text-field>-->

                                <!-- tip osebe (podjetje / fizična oseba) -->
                                    <v-select
                                :items="entities"
                                label="Izberi tip osebe"
                                item-text="name"
                                item-value="id"
                                v-model="entity"
                                :rules="entity_rules"
                                :readonly="!newUser && (existing_permit_user !== null && existing_permit_user.entity_type !== null)"
                                :disabled="!newUser && (existing_permit_user !== null && existing_permit_user.entity_type !== null)"
                                ></v-select>
                                
                                <!-- davčna številka -->
                                <v-text-field
                                    v-model="tax_number"
                                    label="Davčna številka"
                                    required
                                    :rules="tax_number_rules"
                                    v-if="requireTaxNumber"
                                    :readonly="!newUser"
                                    :disabled="!newUser"
                                ></v-text-field>

                                <div class="div__divider__15"></div>

                                <span class="body-2 font-weight-regular"><v-icon class="" medium color="secondary" left>mdi-information</v-icon> {{ noteText}}</span>
                                <div class="div__divider__15"></div>
                                <v-textarea
                                outlined
                                name="input-7-4"
                                label="Vnesite željeno besedilo (opombe, ipd.)"
                                v-model="note"
                                counter
                                required
                                ></v-textarea>
                                <div class="div__divider__15"></div>

                                <!-- tip uporabnika -->
                                <v-select
                                :items="userTypes"
                                label="Izberi tip Blejske kartice"
                                item-text="name"
                                item-value="id"
                                v-model="user_type"
                                :rules="user_type_rules"
                                ></v-select>

                                <!-- registrska številka -->
                                <v-text-field
                                    v-model="registration_plate_number_1"
                                    label="Registrska številka vozila"
                                    :rules="registration_plate_number_rules"
                                    required
                                    :disabled="!newUser && !existing_permit_user"
                                ></v-text-field>

                                <!-- vozilo v osebni lasti ali lasti podjetja -->
                                <v-select
                                :items="vehicleOwnershipTypes"
                                label="Lastništvo vozila"
                                item-text="name"
                                item-value="id"
                                v-model="vehicle_ownership"
                                :rules="vehicle_ownership_rules"
                                :disabled="!newUser && !existing_permit_user"
                                ></v-select>

                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn @click="submitForm()" color="success" block outlined style="margin-bottom:10px;" :disabled="loading || (!newUser && !existing_permit_user)"><v-icon left>mdi-arrow-right</v-icon>Oddaj vlogo</v-btn>
                                <v-btn @click="clearForm()" color="error" block outlined style="margin-bottom:10px;"><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                                <v-btn v-if="application_uuid != null" @click="editApplication()" color="info" block outlined style="margin-bottom:10px;"><v-icon left>mdi-pencil</v-icon>Uredi vlogo</v-btn>
                            </v-card-text>
                            <v-card-actions v-else>
                                <v-btn @click="submitForm()" color="success" text :disabled=" loading || (!newUser && !existing_permit_user)"><v-icon left>mdi-arrow-right</v-icon>Oddaj vlogo</v-btn>
                                <v-btn @click="clearForm()" color="error" text><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                                <v-btn v-if="application_uuid != null" @click="editApplication()" color="info" text><v-icon left>mdi-pencil</v-icon>Uredi vlogo</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-form>
        </v-container>
    </div>
</template>


<script>

import AppFab from '@/components/layout/AppFab'
import router from '@/router'
import AppToolbar from '@/components/layout/AppToolbar'
import imgCardParking from '@/assets/card_blejska_kartica.png'
import { API_ENDPOINT } from '@/helpers/api'
import { EventBus } from '@/main.js'
import axios from 'axios'
import logo from '@/assets/grb_bled_135_135.png'
const SelectPermitHolderDataTable = () => import('@/components/admin/SelectPermitHolderDataTable.vue')
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')
const InfoUserTypeDomacin = () => import('@/components/InfoUserTypeDomacin.vue')
const InfoUserTypeGost = () => import('@/components/InfoUserTypeGost.vue')
import { applicationMediaTypeColor } from '@/helpers/utilities.js'

export default {
    components: {
        FormValidationMessages,
        FormSubmitMessages,
        InfoUserTypeDomacin,
        InfoUserTypeGost,
        SelectPermitHolderDataTable
    },


    data: () => ({
        valid: true,
        application_media_type: null,
        application_uuid: null,
        row: "radio-new-user",
        user_type: null,
        name: null,
        address: null,
        town: null,
        postal_code: null,
        residence_type: null,
        temporary_residence_address: null,
        telephone_number: null,
        tax_number: null,
        subject_type: null,
        email: null,
        phone_number: null,
        emso: null,
        entity: null,
        registration_plate_number_1: null,
        vehicle_ownership: null,
        delivery_type: null,
        enable_submission: false,
        noteText: "Vnesite opombe ipd.",
        note: null,

        userTypes: [],
        residenceTypes: [],
        entities: [],
        vehicleOwnershipTypes: [],
        userTypeSelected: false,

        user_type_rules: [v => !!v || 'Polje tip uporabnika je obvezno'],
        name_rules: [v => !!v || 'Polje ime in priimek je obvezno'],
        address_rules: [v => !!v || 'Polje naslov prebivališča je obvezno'],
        town_rules: [v => !!v || 'Polje kraj je obvezno'],
        postal_code_rules: [v => !!v || 'Polje poštna številka je obvezno'],
        residence_type_rules: [v => !!v || 'Polje tip prebivališča je obvezno'],
        telephone_number_rules: [v => !!v || 'Polje telefonska številka je obvezno'],
        email_rules: [
            v => !!v || 'Polje e-mail je obvezno',
            v => /.+@.+\..+/.test(v) || 'Vnesen elektronski naslov ni pravilen'
        ],
        emso_rules: [v => !!v || 'Polje emso je obvezno'],
        tax_number_rules: [v => !!v || 'Polje davčna številka je obvezno'],
        entity_rules: [v => !!v || 'Polje tip osebe je obvezno'],
        registration_plate_number_rules: [
            v => !!v || 'Polje registrska številka vozila je obvezno',
           // v => ^[a-zA-Z0-9]+$.test(v) || 'Registrska številka lahko vsebuje le številke in črke. Ostali znaki in presledki niso dovoljeni!'
        ],
        vehicle_ownership_rules: [v => !!v || 'Polje lastništvo vozila je obvezno'],
        
        loading:false,

        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],

        show1: false,
        show2: false,

        permit_holders: [],
        existing_permit_user: null,

        dataTableSettings: {
            id: 'dtSelectPermitHolder',
            title: 'Seznam imetnikov dovolillnic',
            sortColumn: ['name'],
            sortDirection: ['asc'],
            progressHeight: 3,
            headers: [
                //{ text: "ID", value: "id", align: "left", visibility: true},
                { text: "Naziv", value: "name", visibility: true},
                { text: "Naslov", value: "address", visibility: true},
                { text: "Poštna št.", value: "postal_code", visibility: true},
                { text: "Kraj", value: "town", visibility: true},
                { text: "Tel. št.", value: "phone_number", visibility: true},
                { text: "Upravljanje", value: "action", align: "right", sortable: false, visibility: true}
            ],
            updateInterval: false,
            rowsPerPage: 10,
            endpoint: 'v1/admin/permit-holders',
            editItem: {
                route: '',
                routeParameterValue: 'uuid'
            },
            deleteItem: {
                vuexAction: null,
                replaceStrWithColumnValue: null,
                confirmationText : null,
                dialogConfirmSuccessText: null,
                dialogConfirmFailText: null
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_select_permit_holders',
                totalItems : {
                    event: ''
                },
                search: 'dt-select-permit-holders-search',
                toggleColumnsId: 'toggle-select-permit-holders-list-columns',
                selectedItem: 'dt-parking-permit-selected-item',
                removeSelectedItem: 'dt-parking-permit-remove-selected-item'
            }


        },
    }),

    watch: {
        user_type(value) {
            if(this.user_type != null && this.user_type != '' && this.user_type != undefined) {
                this.userTypeSelected = true
            } else {
                this.userTypeSelected = false
            }
        },

        registration_plate_number_1(value) {
            if(value != null) {
                //window.console.log(this.registration_plate_number_1)
                this.registration_plate_number_1 = this.registration_plate_number_1.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_plate_number_1 = this.registration_plate_number_1.toUpperCase();
            }
        },

        row(newValue, oldValue) {
            this.existing_permit_user = null
            this.clearForm2()
        },

        existing_permit_user(value){
            let vm = this
            if(value != null && value !== undefined && value != '') {
                this.user_type = null
                vm.name = value.name
                vm.address = value.address
                vm.town = value.town
                vm.postal_code = value.postal_code
                vm.residence_type = (value.residence_type !== null) ?  value.residence_type.uuid : null,
                vm.phone_number = value.phone_number
                vm.emso = value.emso
                vm.entity = (value.entity_type !== null) ?  value.entity_type.uuid : null,
                vm.tax_number = value.tax_number
                
            }

            if(value == null || value === undefined) {
                this.name = null
                this.address = null
                this.town = null
                this.postal_code = null
                this.residence_type = null
                this.phone_number = null
                this.emso = null
                this.entity = null
                this.tax_number = null
                this.$refs.form.resetValidation();
            }
        },
    },

    computed: {
        getLogo() {
            return logo
        },

        requireTaxNumber() {
            if(this.entity == "74c968fc-f8f8-4596-b1f4-3616b4c2c029") {
                return true
            }

            return false
        },

        temporaryResidence() {
            if(this.residence_type == "045189aa-656c-4284-8e8d-b91611e896bf") {
                return true
            }

            return false
        },

        getUserTypeType() {
            if(this.user_type == '2f1448a6-a5ba-49fe-960a-d95461f9efe0') {
                return 1;
            }

            return 2;
        },

        imgParking() {
            return imgCardParking
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        },

        newUser() {
            if(this.row == 'radio-new-user') {
                return true
            }

            return false
        },

        queryParameters() {
            return {
            }
        },
    },

    methods: {
        enableSubmission() {
            if(this.disclaimer) {
                this.enable_submission = true;
            }

            this.enable_submission = false;
        },

        submitForm() {
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []

            let vm = this
            if(this.$refs.form.validate()) {
                vm.$store.commit('SET_LOADER_TEXT', 'Oddaja vloge je v teku...')
                vm.$store.commit('SET_LOADER', true)

                let payload = new FormData();
                payload.append('user_type', vm.user_type)
                payload.append('name', vm.name)
                payload.append('address', vm.address)
                payload.append('town', vm.town)
                payload.append('postal_code', vm.postal_code)
                payload.append('residence_type', vm.residence_type)
                payload.append('phone_number', vm.phone_number)
                payload.append('tax_number', vm.tax_number)
                payload.append('entity', vm.entity)
                payload.append('registration_plate_number_1', vm.registration_plate_number_1)
                payload.append('vehicle_ownership', vm.vehicle_ownership)  
                payload.append('note', vm.note)

                let permit_holder_uuid = (this.existing_permit_user != null) ? this.existing_permit_user.uuid : null;
                payload.append('permit_holder_uuid', permit_holder_uuid);


                vm.loading = true
                axios({
                    method: 'POST',
                    url: API_ENDPOINT + 'v1/admin/applications/office/parking/create',
                    data: payload,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${vm.$store.state.user.access_token}`,
                    }
                })
                .then(response => {
                    window.console.log(response)
                    vm.clearForm()
                    vm.backendMessages.push('Vloga je bila uspešno oddana.')
                        vm.$vuetify.goTo('#form_card', {
                        duration: 1000,
                        offset: 0,
                        easing: 'easeInOutCubic'
                    })
                })
                .catch(error => {
                    window.console.log(error)
                    window.console.log(error.response)

                    if(error.response.status === 422) {
                        //window.console.log(error.response.data.errors)

                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })

                            vm.$vuetify.goTo('#form_card', {
                                duration: 1000,
                                offset: 0,
                                easing: 'easeInOutCubic'
                            })
                        }
                    } else {
                        vm.backendErrorMessages.push('Ups... pri pošiljanju vloge je prišlo do napake. Poskusite ponovno.')
                        vm.$vuetify.goTo('#form_card', {
                            duration: 1000,
                            offset: 0,
                            easing: 'easeInOutCubic'
                        })
                    }

                })
                .then(() => {
                    setTimeout(() => {
                        vm.$store.commit('SET_LOADER', false)
                        vm.$store.commit('SET_LOADER_TEXT', '')
                    }, 600)
                    vm.loading = false
                })

            } 
            else {
                this.$vuetify.goTo('#form_card', {
                    duration: 1000,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
                //window.console.log("Validation ERROR");
            }
        },

        clearForm() {
            //this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.user_type = null
            this.name = null
            this.address = null
            this.town = null
            this.postal_code = null
            this.residence_type = null
            this.phone_number = null
            this.email = null
            this.emso = null
            this.entity = null
            this.registration_plate_number_1 = null
            this.vehicle_ownership = null
            this.note = null
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []

            this.existing_permit_user = null
            if(this.dataTableSettings.eventBus.removeSelectedItem != null && this.dataTableSettings.eventBus.removeSelectedItem != '') {
                EventBus.$emit(this.dataTableSettings.eventBus.removeSelectedItem, null);
            }

            setTimeout(() =>{
                this.$vuetify.goTo('#form_card', {
                    duration: 1000,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
            }, 200)
        },

        clearForm2() {
            //this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.user_type = null
            this.name = null
            this.address = null
            this.town = null
            this.postal_code = null
            this.residence_type = null
            this.phone_number = null
            this.email = null
            this.emso = null
            this.entity = null
            this.registration_plate_number_1 = null
            this.vehicle_ownership = null
            this.note = null
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []

            this.existing_permit_user = null
            if(this.dataTableSettings.eventBus.removeSelectedItem != null && this.dataTableSettings.eventBus.removeSelectedItem != '') {
                EventBus.$emit(this.dataTableSettings.eventBus.removeSelectedItem, null);
            }
        },

        getAdminResources() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            this.$store.dispatch('ADMIN_RESOURCES')
            .then(response => {
                window.console.log("ADMIN RESOURCES")
                window.console.log(response.data)

                if(response.data.hasOwnProperty('user_types')) {
                    vm.$store.commit('SET_USER_TYPES', response.data.user_types)
                    response.data.user_types.forEach(element => {
                        if(element.hasOwnProperty('type')) {
                            if(
                                element.type == 1 || element.type == 2 ||
                                element.type == 9 || element.type == 10 ||
                                element.type == 11 || element.type == 12 ||
                                element.type == 13 || element.type == 14 ||
                                element.type == 15 || element.type == 8 || element.type == 20 || element.type == 22
                            ) {
                                vm.userTypes.push(element)
                            }
                        }
                    })
                }

                if(response.data.hasOwnProperty('residence_types')) {
                    vm.$store.commit('SET_RESIDENCE_TYPES', response.data.residence_types)
                    vm.residenceTypes = response.data.residence_types
                }

                if(response.data.hasOwnProperty('entities')) {
                    vm.$store.commit('SET_ENTITIES', response.data.entities)
                    vm.entities = response.data.entities
                }

                if(response.data.hasOwnProperty('vehicle_ownership_types')) {
                    vm.$store.commit('SET_VEHICLE_OWNERSHIP_TYPES', response.data.vehicle_ownership_types)
                    vm.vehicleOwnershipTypes = response.data.vehicle_ownership_types
                }
                
                if(response.data.hasOwnProperty('permit_statuses')) {
                    vm.$store.commit('SET_PERMIT_STATUSES', response.data.permit_statuses)
                    vm.permitStatuses = response.data.permit_statuses
                }

                if(response.data.hasOwnProperty('parking_lots')) {
                    vm.$store.commit('SET_PARKING_LOTS', response.data.parking_lots)
                    vm.parkingLots = vm.$store.getters.parkingLots
                }

                if(response.data.hasOwnProperty('user_type_parking_lots')) {
                    vm.userTypeParkingLots = response.data.user_type_parking_lots
                    //window.console.log(vm.userTypeParkingLots)
                }

            })
            .catch(error => {
                window.console.error("### ERROR ParkingPermit@getAdminResources")
                window.console.error(error)
            })
            .finally(() =>{
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)
                }, 1000)
            })
        },
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
        this.application_media_type = applicationMediaTypeColor('non-digital')

        var vm = this
        this.$store.dispatch('RESOURCES')
        .then(response => {
            //window.console.log(response.data)
            if(response.data.hasOwnProperty('user_types')) {
                vm.$store.commit('SET_USER_TYPES', response.data.user_types)
            }

            if(response.data.hasOwnProperty('permit_types')) {
                vm.$store.commit('SET_PERMIT_TYPES', response.data.permit_types)
            }

            if(response.data.hasOwnProperty('residence_types')) {
                vm.$store.commit('SET_RESIDENCE_TYPES', response.data.residence_types)
            }

            if(response.data.hasOwnProperty('entities')) {
                vm.$store.commit('SET_ENTITIES', response.data.entities)
            }

            if(response.data.hasOwnProperty('vehicle_ownership_types')) {
                vm.$store.commit('SET_VEHICLE_OWNERSHIP_TYPES', response.data.vehicle_ownership_types)
            }
        })
        .catch(error => {
            window.console.error(error)
        })
        .finally(() =>{
            
            if(vm.$store.getters.userTypes != null) {
                vm.$store.getters.userTypes.forEach(element => {
                    if(element.hasOwnProperty('type')) {
                        if(element.type == 1 || element.type == 2) {
                            vm.userTypes.push(element)
                        }
                    }
                })
            }

            vm.residenceTypes = vm.$store.getters.residenceTypes
            vm.entities = vm.$store.getters.entities
            vm.vehicleOwnershipTypes = vm.$store.getters.vehicleOwnershipTypes
        }) 
    },

    mounted() {
        let vm = this
        this.getAdminResources();
        if(this.dataTableSettings.eventBus.selectedItem != null && this.dataTableSettings.eventBus.selectedItem != '') {
            //window.console.log(this.dataTableSettings.eventBus.selectedItem);
            EventBus.$on(this.dataTableSettings.eventBus.selectedItem, item => {
                window.console.log(item)
                //window.console.log(this.residence_type)
                if(Array.isArray(item) && item.length > 0) {
                    vm.existing_permit_user = item[0]
                } else {
                    vm.existing_permit_user = null
                }
            })
        }
    },

    beforeDestroy() {
        if(this.dataTableSettings.eventBus.selectedItem != null && this.dataTableSettings.eventBus.selectedItem != '') {
            EventBus.$off(this.dataTableSettings.eventBus.selectedItem)
        }
    },

    destroyed() {
        
    }
}

</script>

<style scoped>

</style>